
// -----------student info image slider -----

export const slider2settings = {
  infinite: true,
  arrows: false,
  slidesToShow: 1,
  autoplay: true,
  autoplaySpeed: 1500,
  slidesToScroll: 1
};

// -----------student info rank slider -----
export const settings = {
  speed: 1500,
  autoplay: true,
  autoplaySpeed: 2,
  cssEase: 'linear',
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  infinite: true,
  initialSlide: 1,
  arrows: false,
  buttons: false
};


// -----------student youtube testimonial slider -----
export const youtubeSlider = {
  infinite: true,
arrows: false,
dots: true,
autoplay: true,
slidesToShow: 2,
autoplaySpeed: 3000,
slidesToScroll: 1,
responsive: [
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      dots: true
    }
  },
  {
    breakpoint: 581,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false
    }
  }
]
}

// ----------- testimonials slider -----
export const testimonialSlider = {
  infinite: true,
  arrows: false,
  dots: true,
  slidesToShow: 2,
  autoplay: true,
  autoplaySpeed: 4000,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 581,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
      }
    }
  ]
}

// ----------- blogs slider -----

export const blogSlider = {
  dots: true,
speed: 300,
slidesToShow: 3,
slidesToScroll: 1,
responsive: [
  {
    breakpoint: 992,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
      infinite: true,
      dots: true
    }
  },
  {
    breakpoint: 581,
    settings: {
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: true
    }
  }
]
}
// ----------- mobile siderbar toggle -----


// =====sticky header ===========


// ====== go to scrollTop ===========


// ======= number counter =========




