import * as React from "react";
import PropTypes from "prop-types";
import { useFormContext, Controller } from "react-hook-form";

RHFSelectbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  menus: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.string,
};

export default function RHFSelectbox({ name, label, menus, required, onChange }) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field, fieldState: { error } }) => (
        <>
          <label>{label}</label>
          <select
            required={required}
            {...field}
            className="form-control"
            value={field.value || ""}
            onChange={(...args) => {
              if (onChange && typeof onChange === "function") {
                onChange(...args);
              }
              field.onChange(...args);
            }}
          >
            <option disabled selected>Select Course</option>
            {menus.length
              && menus.map((item) => (
                <option key={item.value + item.name} value={item.value}>
                  {item.name}
                </option>
              ))
            }
          </select>
        </>

      )}
    />
  );
}
